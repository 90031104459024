<template>
  <div class="p-sensor">
    <div class="p-sensorInfo p-sensor__info">
      <div class="l-container">
        <PSensorName
          class="p-sensorInfo__name"
          :shop-name="shopName"
          :badges="badges"
        />
        <div class="p-sensorInfo__data">
          <dl class="p-sensorInfo__sensor">
            <i18n
              tag="dt"
              class="p-sensorInfo__sensorTitle"
              path="エリア別センサー"
            />
            <dd class="p-sensorInfo__sensorName" v-text="sensorName" />
          </dl>
          <PSensorQR class="p-sensorInfo__qr" :qr-string="pageUrl" />
        </div>
      </div>
    </div>
    <div class="p-sensorDetail p-sensor__detail">
      <div class="l-container">
        <div class="p-sensorDetail__top">
          <PShopIotRangeSwitch
            v-if="isSensorShown"
            class="p-sensorDetail__rangeSwitch"
            v-model="selectedRange"
          />
          <ul class="p-sensorHelpList">
            <li class="p-sensorHelpList__item">
              <app-link
                class="p-header__helpLink c-link c-link--help c-link--iconLeft"
                v-t="'グラフの見方について'"
                :to="{ query: $route.query, hash: 'aboutIotGraph' }"
                relpace
              />
            </li>
            <li class="p-sensorHelpList__item">
              <app-link
                class="p-header__helpLink c-link c-link--help c-link--iconLeft"
                v-t="'判定基準値について'"
                :to="{ query: $route.query, hash: 'aboutIotValue' }"
                relpace
              />
            </li>
          </ul>
        </div>
        <div class="p-sensorDetail__iotStatus">
          <ul class="p-sensorDetail__grid">
            <li
              v-for="item in computedDataList"
              :key="item.label"
              class="p-sensorDetail__item"
            >
              <PSensorGraph
                :data="item"
                :rendered-at="updatedAt"
                :range="selectedRange"
              />
            </li>
            <li class="p-sensorDetail__item p-sensorDetail__item--qr">
              <PSensorQR :qr-string="pageUrl" />
            </li>
          </ul>
        </div>
        <div class="p-sensorDetail__bottom">
          <i18n
            tag="app-link"
            class="c-button c-button--assist p-sensorDetail__back"
            :to="backLinkTo"
            path="店舗詳細にもどる"
          />
          <i18n
            tag="p"
            class="p-sensorDetail__updatedAt"
            path="データ取得日時：{text}"
          >
            <template #text>{{
              (dataLastDate && $options.filters.dayjsFormat(dataLastDate)) ||
              '-'
            }}</template>
          </i18n>
        </div>
      </div>
    </div>
    <PShopAboutIotGraphModal
      :close-to="{ name: 'SensorDetail', query: $route.query }"
    />
    <PShopAboutIotValueModal
      :close-to="{ name: 'SensorDetail', query: $route.query }"
    />
  </div>
</template>

<script>
import PSensorName from '@/components/shop/sensor/PSensorName.vue'
import PShopIotRangeSwitch from '@/components/shop/PShopIotRangeSwitch.vue'
import PSensorGraph from '@/components/shop/sensor/PSensorGraph.vue'
import PSensorQR from '@/components/shop/sensor/PSensorQR.vue'
import PShopAboutIotGraphModal from '@/components/shop/PShopAboutIotGraphModal.vue'
import PShopAboutIotValueModal from '@/components/shop/PShopAboutIotValueModal.vue'

export default {
  name: 'PSensorPresentation',
  components: {
    PSensorName,
    PShopIotRangeSwitch,
    PSensorGraph,
    PSensorQR,
    PShopAboutIotGraphModal,
    PShopAboutIotValueModal,
  },
  props: {
    isPublicResources: {
      type: Boolean,
      default: true,
    },
    shop: {
      type: Object,
      default: null,
    },
    shopConfirmList: {
      type: Array,
      required: true,
      default: () => [],
    },
    sensor: {
      type: Object,
      default: null,
    },
    updatedAt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedRange: '1day',
    }
  },
  computed: {
    dataLastDate() {
      let obj
      if (this.sensor.dataList[0].latestStatus) {
        obj = this.sensor.dataList[0].latestStatus.original
      } else if (this.sensor.dataList[1].latestStatus) {
        obj = this.sensor.dataList[1].latestStatus.original
      } else if (this.sensor.dataList[2].latestStatus) {
        obj = this.sensor.dataList[2].latestStatus.original
      } else if (this.sensor.dataList[3].latestStatus) {
        obj = this.sensor.dataList[3].latestStatus.original
      } else {
        obj = this.sensor.dataList[4].latestStatus.original
      }
      return (
        this.isSensorShown &&
        // this.sensor.dataList[0].latestStatus.original.timestamp
        obj.timestamp
      )
    },
    backLinkTo() {
      return {
        name: 'ShopDetail',
        params: { portalId: this.shop.portalId, id: this.shop.pageUrl },
        query: { currentTab: 'SensorInfo' },
      }
    },
    shopName() {
      let shopName
      switch (this.$i18n.locale) {
        case 'en':
          shopName = this.shop.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return shopName || this.shop.name
    },
    sensorName() {
      let sensorName
      switch (this.$i18n.locale) {
        case 'en':
          sensorName = this.sensor.sensorNameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return sensorName || this.sensor.sensorName
    },
    badges() {
      const result = []

      if (this.isSensorShown) {
        result.push('iotSensor')
      }
      if (this.shopConfirmList.length) {
        result.push('shopConfirm')
      }

      return result
    },
    isSensorShown() {
      return this.sensor !== null
    },
    sensorDataList() {
      return (this.sensor && this.sensor.dataList) || []
    },
    computedDataList() {
      return this.sensorDataList.filter((data) => data.dataList)
    },
    pageUrl() {
      return `${location.origin}/#/sensorinfo?id=${this.$route.query.id}`
    },
  },
}
</script>
