import { requestPublicApi } from '@/assets/modules/request'

/**
 * センサー紐付け店舗データを取得する
 * @param {string} sensorId
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse>}
 */
export function getSensorShopData(sensorId) {
  return requestPublicApi.get(`/sensors/${sensorId}/shops`)
}
