<template>
  <div class="p-sensorName">
    <h2 class="p-sensorName__text" v-text="shopName" />
    <ul class="p-sensorName__badges">
      <li v-for="badge in badges" :key="badge" class="p-sensorName__badge">
        <CTooltip>
          <template #target>
            <PSensorBadge :badge="badge" />
          </template>
          <template #content>
            <i18n :path="badgeBalloon[badge]" />
          </template>
        </CTooltip>
      </li>
    </ul>
  </div>
</template>

<script>
import CTooltip from '@/components/common/CTooltip.vue'
import PSensorBadge, {
  BadgeKeys,
} from '@/components/shop/sensor/PSensorBadge.vue'

export default {
  name: 'PSensorName',
  components: {
    CTooltip,
    PSensorBadge,
  },
  props: {
    shopName: {
      type: String,
      default: '',
    },
    badges: {
      type: Array,
      default: () => [],
      validator: (val) => val.every((badge) => BadgeKeys.includes(badge)),
    },
  },
  computed: {
    badgeBalloon() {
      return {
        iotSensor: 'IoTセンサーによるリアルタイム状況を表示しています。',
        shopConfirm: '第三者によるチェックを行っています。',
      }
    },
  },
}
</script>
