<template>
  <div class="p-sensorQR">
    <img
      class="p-sensorQR__check"
      src="/img/shop/img_qrcodeDecolation_check.png"
    />
    <div class="p-sensorQR__img">
      <canvas ref="canvas" class="p-sensorQR__canvas" />
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'PSensorQR',
  props: {
    qrString: {
      type: String,
      required: true,
    },
  },
  mounted() {
    QRCode.toCanvas(this.$refs.canvas, this.qrString, (error) => {
      if (error) this.$toasted.show(error)
    })
  },
}
</script>
