import store from '@/store'
import { Response } from '../modules/request/Response'
import { getSensorData } from './api/getSensorData'
import { getSensorList } from './api/getSensorList'
import { getSensorShopData } from './api/getSensorShopData'
import { getShopData } from './api/getShopData'
import { getSensorDataJson } from './json/getSensorDataJson'
import { getSensorListJson } from './json/getSensorListJson'
import { getShopConfirmListJson } from './json/getShopConfirmListJson'
import { getShopDataJson } from './json/getShopDataJson'
import { SensorDetailViewModel } from './viewModel/SensorDetailViewModel'

export async function requestSensorDetailData(sensorId) {
  const isLoggedIn = store.getters['auth/isLoggedIn']
  /** データの取得元がフリー公開かどうか */
  let isPublicResources = true

  const sensorShopDataResponse = await getSensorShopData(sensorId)

  if (sensorShopDataResponse.isError) {
    return new Response({
      status: 404,
    })
  }

  const { facilityIdSectionId: id, portalId, pageUrl } = sensorShopDataResponse.data

  let shopDataResponse = await getShopDataJson(id)

  // ログイン状態でトラスト証明書(店舗情報)JSONが取得できない場合はAPIでのリクエスト試行する
  if (isLoggedIn && shopDataResponse.isError) {
    shopDataResponse = await getShopData(id)
    isPublicResources = false
  }

  // トラスト証明書(店舗情報)が取得できなければ404
  if (shopDataResponse.isError) {
    return new Response({
      status: 404,
    })
  }

  // 最初に全てのリクエストを実行する
  /** 第三者チェック情報リクエスト */
  const shopConfirmListRequest = isPublicResources
    ? getShopConfirmListJson(id)
    : null // 限定公開の場合はリクエストしない
  /** センサーリスト情報リクエスト */
  const sensorListRequest = isPublicResources
    ? getSensorListJson(id)
    : getSensorList(id)

  /** viewModel */
  const shopData = new SensorDetailViewModel(
    id,
    shopDataResponse.data,
    isPublicResources,
    portalId,
    pageUrl
  )

  // センサーリストのデータリクエストを作成する
  let sensorDataRequest = isPublicResources ? getSensorDataJson : getSensorData
  let sensorList = []

  // センサーリストの取得を待機
  const sensorListResponse = await sensorListRequest

  if (!sensorListResponse.isError) {
    sensorList = sensorListResponse.data.sensorList.map((data) => ({
      isPublicResource: isPublicResources,
      ...data,
    }))
  }

  // 該当のセンサーデータがあるか確認
  let sensorMetaData = sensorList.find((data) => data.sensorId === sensorId)

  // 該当のセンサーデータがない場合はAPIリクエストを実行する
  if (isPublicResources && !sensorMetaData) {
    /** センサーリスト追加情報リクエスト */
    const sensorListExtraRequest =
      isLoggedIn && isPublicResources ? getSensorList(id) : null

    const sensorListExtraResponse = sensorListExtraRequest
      ? await sensorListExtraRequest
      : null

    if (sensorListExtraResponse && !sensorListExtraResponse.isError) {
      sensorList = [
        ...sensorList,
        ...sensorListExtraResponse.data.sensorList.map((data) => ({
          isPublicResource: false,
          ...data,
        })),
      ]
    }

    sensorMetaData = sensorList.find((data) => data.sensorId === sensorId)

    if (sensorMetaData) {
      sensorDataRequest = getSensorData
    }
  }

  shopData.sensorMetaData = sensorMetaData

  const shopConfirmListResponse = shopConfirmListRequest
    ? await shopConfirmListRequest
    : null
  const sensorDataResponse =
    sensorMetaData && sensorDataRequest
      ? await sensorDataRequest(
        id,
        sensorMetaData.sensorId,
        sensorMetaData.roomId
      )
      : null

  if (shopConfirmListResponse && !shopConfirmListResponse.isError) {
    shopData.shopConfirmListData = shopConfirmListResponse.data
  }

  if (sensorDataResponse && !sensorDataResponse.isError) {
    shopData.sensorData = {
      sensorId: sensorMetaData.sensorId,
      roomId: sensorMetaData.roomId,
      dataList: sensorDataResponse.data.sensorData,
    }
  }

  if (!shopData.sensor) {
    return new Response({
      status: 404,
    })
  }

  return new Response({
    status: 200,
    data: shopData,
  })
}
