export class SensorDetailViewModel {
  constructor(id, shopData, isPublicResources, portalId, pageUrl) {
    /** @type {import('@/assets/request/types/shopData').ShopDataOriginal} */
    this.id = id
    this._shopData = shopData
    this.isPublicResources = isPublicResources
    this.sensorData = null
    this.sensorMetaData = null
    this.shopConfirmListData = []
    this._portalId = 'shops'

    this.portalId = portalId
    this.pageUrl = pageUrl
  }

  get shopData() {
    return this._shopData.certificateTable[0].certificateInfo
  }

  get shop() {
    const { shopName, shopNameEnglish } = this.shopData.extendedItemsArea

    return {
      id: this.id,
      portalId: this.portalId,
      name: shopName,
      nameEnglish: shopNameEnglish,
      pageUrl: this.pageUrl,
    }
  }

  get sensor() {
    return this.sensorData && this.sensorMetaData
      ? {
          ...this.sensorMetaData,
          ...this.sensorData,
        }
      : null
  }

  get portalId() {
    return this._portalId
  }

  set portalId(portalId) {
    this._portalId = /s$/.test(portalId) ? portalId : `${portalId}s`
  }

  get hasEnglishPage() {
    return this.shopData.extendedItemsArea.englishPageCreateFlag === '0'
      ? false
      : true
  }

  get pageUrl(){
    return this._pageUrl
  }

  set pageUrl(pageUrl){
    this._pageUrl = pageUrl
  }
}
