<template>
  <SensorPresentation
    v-if="!isLoading"
    :key="presentationKey"
    :is-public-resources="isPublicResources"
    :shop="shop"
    :shop-confirm-list="shopConfirmList"
    :sensor="sensor"
    :updated-at="updatedAt"
  />
</template>

<script>
import SensorPresentation from '@/components/shop/sensor/SensorPresentation.vue'
import { requestSensorDetailData } from '@/assets/request/requestSensorDetailData'
import { requestSensorData } from '@/assets/request/requestSensorData'
import { requestCanceler } from '@/assets/modules/request'
import dayjs from 'dayjs'
import { postAccessLog } from '@/assets/request/api/postAccessLog'

export default {
  name: 'SensorDetail',
  components: {
    SensorPresentation,
  },
  metaInfo() {
    return {
      title: this.shop === null ? '' : this.shopName,
    }
  },
  data() {
    return {
      isLoading: true,
      isPublicResources: true,
      shop: null,
      shopConfirmList: [],
      sensor: null,
      updatedAt: null,
      timer: false,
    }
  },
  computed: {
    width() {
      switch (this.$route.query.type) {
        case 'mobile':
          return 375
        default:
          return 1080
      }
    },
    presentationKey() {
      return `${this.$route.params.portalId}-${this.$route.params.id}`
    },
    shopName() {
      let shopName
      switch (this.$i18n.locale) {
        case 'en':
          shopName = this.shop.nameEnglish
          break
      }
      // 英語情報が存在しない場合、日本語情報にフォールバック
      return shopName || this.shop.name
    },
  },
  beforeRouteEnter(to, _from, next) {
    // センサーIDがなければ404
    if (typeof to.query.id !== 'string') {
      next({ name: 'Error', params: { portalId: 'shops' } })
      return
    }

    requestSensorDetailData(to.query.id).then((response) => {
      if (response.isError) {
        next({ name: 'Error', params: { portalId: 'shops' } })
        return
      }

      next((vm) => {
        vm.setData(response.data)
      })
    })
  },
  beforeRouteUpdate(to, from, next) {
    // センサーIDがなければ404
    if (typeof to.query.id !== 'string') {
      next({
        name: 'Error',
        params: { portalId: this.$store.getters['common/portalId'] },
      })
      return
    }

    if (from.name === to.name && from.query.id === to.query.id) {
      next()
      return
    }

    requestCanceler.cancel()
    requestSensorDetailData(to.query.id).then((response) => {
      if (response.isError) {
        next({
          name: 'Error',
          params: { portalId: this.$store.getters['common/portalId'] },
        })
        return
      }

      this.setData(response.data)
      next()
    })
  },
  beforeRouteLeave(_to, _from, next) {
    requestCanceler.cancel()
    if (this.timer !== false) {
      clearTimeout(this.timer)
    }
    next()
  },
  mounted(){
    postAccessLog(this.shop.id, 'detail', location.href)
  },
  methods: {
    setData(vm) {
      this.shop = vm.shop
      this.shopConfirmList = vm.shopConfirmList
      this.updateUpdatedAt()
      this.sensor = vm.sensor
      this.isPublicResources = vm.isPublicResources
      this.isLoading = false
      this.$store.commit('common/setOverridePortalId', vm.shop.portalId)

      this.$store.commit('detail/setHasEnglishPage', vm.hasEnglishPage)
      this.startUpdateSensorDataTimer()
    },
    startUpdateSensorDataTimer() {
      if (this.timer !== false) {
        clearTimeout(this.timer)
        this.timer = false
        requestCanceler.cancel()
      }

      if (!this.sensor) return

      this.timer = setTimeout(() => {
        requestSensorData(
          this.shop.id,
          this.sensor.sensorId,
          this.sensor.roomId
        ).then((response) => {
          if (response.isError) {
            return
          }
          this.updateUpdatedAt()
          this.sensor.dataList = response.data.sensorData
          this.timer = false

          this.startUpdateSensorDataTimer()
        })
      }, 120000) // 2分に1度更新
    },
    updateUpdatedAt() {
      this.updatedAt = dayjs(new Date()).second(0).millisecond(0).toISOString()
    },
  },
}
</script>
